@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  font-family: 'Cornero', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

} */

body {
    /* background-color: #555555; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* @font-face {
  font-family: 'Cornero';
  src: url(../public/fonts/Cornero.otf) format('OpenType');
  font-weight: normal;
  font-style: normal;
} */

/* code {
  font-family: 'Cornero';
} */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#toggle:checked + label > span:first-child {
    @apply left-[22px];
}

.video-loader {
    position: relative;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: 99;
}

.video-loader .clip-half-one {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
    clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
}

.video-loader .clip-half-two {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
    clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
}

.split-section {
    width: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: width 1s ease;
}

.theme-gradient {
    background: rgb(33, 191, 1);
    background: radial-gradient(
        circle,
        rgba(33, 191, 1, 1) 0%,
        rgba(209, 241, 0, 1) 100%
    );
}

.close-button {
    position: absolute;
    /* / top: 10px;/ */
    right: 50%;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
    bottom: -58px;
    transform: translate(50%, 0);
    display: flex;
    justify-content: center;
}

/* .blue-gradient {
  text-align: center;
  background-color: #000000;
  background-image: linear-gradient(135deg, #777777 0%, #004444 50%);
} */

.header-background {
    text-align: center;
    /* background-color: #55AA55; */
    background-color: #000000;
}

.header-background h3 {
    width: 100%;
}
.header-background > button {
    display: none;
}

.header-background svg {
    color: #fff;
}

.header-background svg:hover {
    color: #000000;
}

.gray-background {
    /* text-align: center; */
    background-color: #5a5a5a;
}

.hero-gradient {
    background: rgba(13, 63, 96, 0.785);
}

.form-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: darkgray;
}

/* .form-header h3 {
  width: 100%;
  text-align: center;
  color: #fff;
} */

.form-title {
    color: #ffffff;
    font-size: 25px;
    text-align: center;
    font-family: 'Cornero';
}

.swiper {
    z-index: 99;
}

[data-cky-tag='detail-powered-by'] {
    display: none !important;
}

.ReactCollapse--collapse {
    transition: height 500ms;
}

.slider-parent {
    width: 100%;
    position: relative;
}

.buble {
    position: absolute;
}

.preload-images {
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
}

.preload-images img {
    display: none;
}


.iphone-frame::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40%;
    height: 0px;
    background: #000;
    border-radius: 0 0 20px 20px;
    z-index: 2;
}

.iphone-frame {
    position: relative;
    border: 12px solid #000;
    border-radius: 48px;
    background: #000;
    box-shadow: 
        0 0 15px rgba(255, 255, 255, 0.3),
        0 0 30px rgba(64, 164, 241, 0.2),     /* Blue glow */
        0 0 45px rgba(128, 0, 255, 0.1);      /* Purple glow */
    animation: glowPulse 3s infinite;
    transform: perspective(1000px) rotateX(2deg);
}

@keyframes glowPulse {
    0% {
        box-shadow: 
            0 0 15px rgba(255, 255, 255, 0.3),
            0 0 30px rgba(64, 164, 241, 0.2),
            0 0 45px rgba(128, 0, 255, 0.1);
    }
    50% {
        box-shadow: 
            0 0 20px rgba(255, 255, 255, 0.4),
            0 0 40px rgba(64, 164, 241, 0.3),
            0 0 60px rgba(128, 0, 255, 0.2);
    }
    100% {
        box-shadow: 
            0 0 15px rgba(255, 255, 255, 0.3),
            0 0 30px rgba(64, 164, 241, 0.2),
            0 0 45px rgba(128, 0, 255, 0.1);
    }
}