body {
    background-color: #222;
    color: #fff;
  }
  
  .privacy-statement {
    font-family: Arial, sans-serif;
    color: #fff; /* Set the text color to white */
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #333;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .privacy-statement h1,
  .privacy-statement h2 {
    color: #55ff52;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .privacy-statement p {
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .privacy-statement ol {
    margin-left: 20px;
  }
  
  .privacy-statement li {
    margin-bottom: 10px;
  }
  
  .privacy-statement table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .privacy-statement th,
  .privacy-statement td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #555;
  }
  
  .privacy-statement th {
    background-color: #007acc;
    color: white;
  }
  
  .privacy-statement tr:nth-child(even) {
    background-color: #444;
  }
  
  .privacy-statement tr:hover {
    background-color: #555;
  }

  .privacy-statement .tick {
    color: lightgreen;
  }
  
  .privacy-statement .cross {
    color: lightcoral;
  }
  