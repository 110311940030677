.pre-sale-offer {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

.banner {
  max-width: 100%;
  height: auto;
}

.release-date,
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.digital-text {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
  color: #ffffff;
}

.digital-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  color: #f5853b;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-family: Roboto, sans-serif;
}

.digit {
  font-family: 'Digital-7', sans-serif;
  font-size: 24px;
  margin: 0 2px;
}

.subscriptions {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.subscription {
  background-color: rgba(51, 51, 51, 0.8);
  backdrop-filter: blur(5px);
  padding: 20px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subscription-title {
  color: #007acc;
  margin-top: 0;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.subscription-title-1.active {
  color: #3cb9f8;
}

.subscription-title-2.active {
  color: #03c865;
}

.discount {
  color: #55ff52;
  font-weight: bold;
}

.discount.active {
  color: #d9ff00;
}

.discount-list {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.discount-list li {
  margin-bottom: 5px;
  font-weight: bold;
  color: #8c8c8c;
}

.discount-list li.active {
  color: #d9ff00;
}

/* Responsive styles */
@media (max-width: 768px) {
  .subscriptions {
    flex-direction: column;
    align-items: center;
  }

  .subscription {
    width: 80%;
  }
}

.quick-information {
  background-color: rgba(51, 51, 51, 0.8);
  backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-header {
  color: #e5eef3;
  margin-top: 0;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.info-section {
  margin-bottom: 20px;
}

.info-heading {
  color: #3cb9f8;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.info-section p {
  color: #ffffff;
  margin: 5px 0;
}