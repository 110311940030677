/* GettingStarted.css */

body {
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  /* Dark background */
  color: #E8E8E8;
  /* Light font color */
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px 0px;
}

.section {
  margin-bottom: 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent dark background for each section */
  border-radius: 8px;
}

.section h2 {
  color: #FFFFFF;
  /* Bright white for titles */
  font-size: 28px;
  /* Medium-like title size */
  font-weight: 400;
  /* Bold font weight for titles */
  margin-bottom: 16px;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: #00A86B;
  /* Specify the underline color */
  text-decoration-thickness: 2px;
  /* Control the thickness of the underline */
  text-underline-offset: 8px;
  /* Adjust the offset from the text */
}

.panel-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.panel {
  flex-basis: 48%;
  padding: 20px;
  border-radius: 8px;
}

.image-panel img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.text-panel {
  background-color: rgba(36, 36, 36, 0.7);
  /* Slightly lighter dark shade for contrast */
  color: #E8E8E8;
  /* Light font color for readability */
  border-radius: 8px;
  padding: 20px;
}

.text-panel h3 {
  font-family: 'Cornero';
  color: #AAAAAA;
  /* Slightly brighter white for subheadings */
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 12px;
}

.text-panel p {
  color: #888888;
  /* Slightly brighter white for subheadings */

  font-weight: 500;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .panel-container {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .panel {
    flex-basis: auto;
  }
}