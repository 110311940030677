#line-wrapper-0 {
    animation: desc 1.5s linear;
    opacity: 0;
    animation-delay: 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#line-wrapper-1 {
    animation: desc 1.5s linear;
    animation-delay: 4.5s;
    opacity: 0;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#line-wrapper-2 {
    animation: desc 1.5s linear;
    animation-delay: 6s;
    opacity: 0;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#line-wrapper-3 {
    animation: desc 1.5s linear;
    animation-delay: 7.5s;
    opacity: 0;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}



@keyframes desc {
    0% {
        scale: 1.1;
        opacity: 0.2;
    }

    12.5% {
        scale: 0.8;
        opacity: 0.7;
    }

    25% {
        scale: 0.7;
        opacity: 0.7;
    }

    50% {
        scale: 0.95;
        opacity: 0.7;
    }

    100% {
        scale: 1;
        opacity: 1;
    }
}

.hero-right {
    opacity: 0;
    transform: scale(0.7);
    transition: opacity 0.3s, transform 0.3s;
}

.animate {
    opacity: 1;
    transform: scale(1);
    letter-spacing: 0;
}

.language-select {
    font-family: 'Cornero';
}

.letter-spacing-1 {
    letter-spacing: 1px;
}

.letter-spacing-2 {
    letter-spacing: 2px;
}

.glow-yellow {
    cursor: pointer;
    display: flex; /* Change inline-block to flex */
    align-items: center; /* Vertically centers contents */
    justify-content: center; /* Centers contents horizontally if needed */
    padding: 7px 23px;
    border-radius: 8px;
    color: #fff;
    background-color: #ffffff00;
    box-shadow: 0 10px 11px -5px #b3b3b3, inset 0 -13px 15px -9px #36c62f00;
    transition: color 1s;
    border-width: 2px;
    border-color: #55ff52;
    position: relative;
    
    overflow: hidden;
    font-family: 'Cornero', sans-serif;
}

.glow-gray {
    cursor: pointer;
    display: inline-block;
    padding: 7px 23px;
    border-radius: 25px;
    color: #DDDDDD;
    background-color: rgb(10, 10, 10);
    box-shadow:
        0 10px 11px -5px #111,
        inset 0 -13px 15px -9px #111;
    transition: color 1s;
    position: relative;
    z-index: 1;
    overflow: hidden;
    font-weight: 700;
}

.btn-background {
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: linear-gradient(#44ef47e7, #44ef47e7);
    transition: 0.5s;
    z-index: -1;
    border-radius: 50%;
}

.glow-yellow:hover {
    color: #ffffff;
    text-shadow: 1px 0 5px #fff;
}

.glow-yellow:hover .btn-background {
    width: 150%;
    height: 500%;
}

.glow-icon {
    filter: drop-shadow(0 0 2px rgb(244, 244, 243)); /* Adjust color and spread as needed */
}

/* .mirror-ball-left {
    position: absolute;
    z-index: 3;
    opacity: 0;
    top: calc(30% - 20px);
    left: 0;
    border-radius: 50%;
    box-shadow:
        0 0 60px 30px #fff,
        0 0 100px 60px rgb(240, 237, 240),
        0 0 140px 90px #0ff;
    animation: moveRight 2.5s linear 2s 1;
}
.mirror-ball-right {
    position: absolute;
    z-index: 3;
    opacity: 0;
    top: calc(30% - 20px);
    right: 0;
    border-radius: 50%;
    box-shadow:
        0 0 60px 30px #fff,
        0 0 100px 60px rgb(240, 237, 240),
        0 0 140px 90px #0ff;
    animation: moveLeft 2s linear 2s 1;
} */


@keyframes moveRight {
    0% {
        left: 15%;
        opacity: 0.2;
        /* opacity: 0.5; */
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        left: 95%;
    }
}

@keyframes moveLeft {
    0% {
        /* opacity: 0.5; */
        right: 15%;
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        right: 70%;
    }
}

@keyframes wipe-in-right {
    from {
        clip-path: inset(0 100% 0 0);
    }

    to {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes hero-card-logo {
    from {
        opacity: 0.5;
        clip-path: inset(0 100% 0 0);
    }

    to {
        opacity: 1;
        clip-path: inset(0 0 0 0);
    }
}

@keyframes hero-title {
    from {
        opacity: 0.5;
        clip-path: inset(0 100% 0 0);
    }

    to {
        opacity: 1;
        clip-path: inset(0 0 0 0);
    }
}

.night {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 50px;
    z-index: -1;
}

.shooting_star {
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 0;
    height: 35px;
    background: linear-gradient(-45deg, #fff, rgba(0, 0, 255, 0));
    border-radius: 999px;
    -webkit-filter: drop-shadow(0 0 6px #fff);
    filter: drop-shadow(0 0 6px #fff);
    -webkit-animation:
        tail 2000ms ease-in,
        shooting 2000ms ease-in;
    animation:
        tail 2000ms ease-in,
        shooting 2000ms ease-in;
}

.shooting_star::before,
.shooting_star::after {
    content: '';
    position: absolute;
    z-index: 3;
    top: 0px;
    right: 0;
    height: 52px;
    -webkit-transform: translateX(50%) rotateZ(45deg);
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    -webkit-animation: shining 2000ms ease-in;
    animation: shining 2000ms ease-in;
}

@-webkit-keyframes tail {
    0% {
        width: 0;
    }

    30% {
        width: 100px;
    }

    100% {
        width: 0;
    }
}

@-webkit-keyframes shining {
    0% {
        width: 0;
    }

    50% {
        width: 50px;
    }

    100% {
        width: 50px;
    }
}

@-webkit-keyframes shooting {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(500px);
        transform: translateX(500px);
    }
}

@-webkit-keyframes sky {
    0% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    100% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }
}

.shooting_star::after {
    -webkit-transform: translateX(50%) rotateZ(-45deg);
    transform: translateX(50%) rotateZ(-45deg);
}

.shooting_star:nth-child(1) {
    bottom: 0;
    left: 0;
}

.shooting_star:nth-child(1)::before,
.shooting_star:nth-child(1)::after,
.shooting_star:nth-child(1)::after {
    bottom: 0;
    left: 0;
}

@keyframes tail {
    0% {
        width: 0;
    }

    30% {
        width: 100px;
    }

    100% {
        width: 0;
    }
}

@keyframes shining {
    0% {
        width: 0;
    }

    50% {
        width: 50px;
    }

    100% {
        width: 50px;
    }
}

@keyframes shooting {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(500px);
        transform: translateX(500px);
    }
}

@keyframes sky {
    0% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    100% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }
}

@keyframes swiss_ball {
    0% {
        left: 0%;
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    100% {
        left: 100%;
        opacity: 0;
    }
}

.hero-image {
    font-family: 'Roboto';
    font-weight: bold; /* Add this line to make text bold */
    font-size:24px;
    position: relative;
    animation: wipe-in-right linear 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    text-shadow: 1px -1px 2px rgb(219, 219, 219);
}

.hero-title {
    position: relative;
    opacity: 0;
    animation: hero-title linear 0.65s;
    animation-iteration-count: 1;
    animation-delay: 2.5s;
    animation-fill-mode: forwards;
    text-shadow: 2px -1px 2px rgba(0, 0, 0, 1);
}

.hero-card-logo {
    position: relative;
    opacity: 0;
    /* animation: hero-card-logo; */
    animation: hero-card-logo linear 0.75s;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.hero-start-now {
    scale: 0;
    opacity: 0;
    animation: fadeInAnimationStartNow linear 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 2.5s;
}

@keyframes fadeInAnimationCover {
    0% {
        max-width: 100%;
        opacity: 0;
    }

    50% {
        opacity: 0.5;
        max-width: 50%;
    }

    100% {
        opacity: 1;
        max-width: 0%;
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInAnimationStartNow {
    0% {
        scale: 0;
    }

    100% {
        scale: 1;
        opacity: 1;
    }
}

@font-face {
  font-family: 'Cornero';
  src: url(../../public/fonts/Cornero.otf) format('OpenType');
  font-weight: normal;
  font-style: normal;
}

#nav-ul {
    display: flex;
    align-items: center;
    justify-content: center;
}


#nav-ul li>a {
    font-family: 'Cornero';
    padding: 3px;
    color: white !important;
    font-family: 'Cornero', sans-serif;
    /* Add bold font style */

}

#nav-ul li {
    padding: 10px;
}

#nav-ul li:hover {
    background-color: #000;
    border-radius: 10px;
}

#nav-ul li.active-link {
    background-color: #000;
    border-radius: 10px;
}

#nav-ul li.active-link:focus {
    background-color: #000;
}

#nav-ul li.active-link a {
    border-bottom: #00cc00 solid 3px;
}

#nav-ul li.active-link a:focus {
    border-bottom: #00cc00 solid 3px;
}

@media (max-width: 768px) {
    #nav-ul li {
        display: flex;
        justify-content: center;
        margin: 3px 0;
        width: 100%;
    }

    #nav-ul ul>li>button {
        font-size: 15px;
        line-height: 18px;
    }
}

/* src/styles/main.css */
:root {
    --dark-grey: #333;
    --light-grey: #16141f;
    --white-color: #ffffff;
    --primary-color: #007acc;
    --secondary-color: #4caf50;
    --hover-effect: scale(1.03);
    --font-color: #00cc00;
    /* Example scale effect */
}

/* body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: var(--light-grey);
    color: var(--white-color);
  } */

/* .container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 20px;
  } */

.card {
    /* border: 1px solid #ddd; */
    transition: transform 0.2s;
    border-radius: 10px;
    /* Adds rounded corners to the card */
    overflow: hidden;
    /* Ensures the image and content inside card respect the border-radius */
}

.card:hover {
    transform: scale(1.05);
}

.card img {
    width: 100%;
    height: auto;
    /* Adjust based on your design needs */
    border-radius: 12px;

    /* Rounds the top corners of the image */
    /* If you use overflow: hidden on .card, you may not need to set border-radius on the img */
}

.card img:hover {
    transform: scale(1.1);
    /* Zoom effect on image hover */
}

.card-contents {
    color: #33ff33;
    /* padding: 15px; */
}

.card-content {
    color: var(--white-color);
    font-family: 700;
    /* padding: 15px; */
}

.card-title {
    margin-top: 0;
    color: var(--primary-color);
}

.card-description {
    color: var(--dark-grey);
}

.card-title,
.card-description {
    margin: 0;
    /* Removes default margins */
    padding: 0;
    /* Adjusts padding as needed */
    border: none;
    /* Ensures no border outline */
}

.card a,
.card a:focus {
    text-decoration: none;
    /* Removes underline from links */
    outline: none;
    /* Removes focus outline */
    color: inherit;
    /* Inherits text color from parent, useful if .card or .card-content sets a specific color */
}

.tag {
    display: inline-block;
    /* background-color: red; */
    border: none;
    color: white;
    font-weight: 600;
    font-weight: 16px;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    /* border-radius: 50px; */
}

.tags {
    padding: 50px 0px 30px 120px;
}

.tags :hover {
    background-color: #1b1927 !important;
    /* Green background for active tags */
    color: #00cc00;
}

.tag-on {
    background-color: #1b1927;
    /* Green background for active tags */
    color: #00cc00;
    padding: 15px;
}

.tag-off {
    /* background-color: #333; */
    /* Dark grey background for inactive tags */
    color: white;
}

.tag-filter {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-bottom: 20px;
}

.tag:hover {
    background: var(--primary-color);
}

.horizontal-band {
    background-color: var(--dark-grey);
    color: white;
    margin: 40px 0;
    padding: 20px;
    text-align: center;
}

.horizontal-band a {
    color: white;
    text-decoration: none;
    border-bottom: 2px solid transparent;
}

.horizontal-band a:hover {
    border-color: white;
}

/* body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

/* .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
    gap: 20px;
    max-width: 1200px;
   
    margin: 0 100px;
    
    padding: 20px;
  } */

.main-latest-blog {
    display: flex;
    /* grid-template-columns: repeat(3, 1fr);  Creates 3 columns */
    gap: 20px;
    /* Space between items */
    width: 96%;
    /* Match header width for alignment */
    margin: 0 auto;
    /* Center the gallery */
    padding: 20px;
}

.latest-blog {
    /* display: flex; */
    /* grid-template-columns: repeat(3, 1fr);  Creates 3 columns */
    width: 48%;
    /* Match header width for alignment */
    margin: 100px 0 10px 110px;
    /* Center the gallery */
    /* padding: 20px; */
}

.ai-blog {
    /* display: flex; */
    /* grid-template-columns: repeat(3, 1fr);  Creates 3 columns */

    width: 56%;
    /* Match header width for alignment */
    /* margin: 0px 70px 0px 0px; */
    /* Center the gallery */
    /* padding: 0px 0px 0px 20px; */
}

.other-blog {
    background-color: #1b1927;
    padding: 80px 5px 40px 20px;
    width: 26%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto;
}

.partnership-blog {
    width: 56%;
    /* Match header width for alignment */
    /* margin: 0px 70px 0px 0px; */
    /* Center the gallery */
    /* padding: 0px 0px 0px 20px; */
}

.button-read-more {
    width: 110px;
    height: 40px;
    font-weight: 700;
    font-size: 14px;
    background-color: #00cc00;
    color: #ffffff;
    border: none;
    border-radius: 15px;
    margin-top: 30px;
}

.card-excerpt {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}

.header-title {
    color: #00cc00;
}

.add-desgin {
    padding-bottom: 20px;
}

.header-box {
    /* or whatever max width you prefer */
    margin: 50px 0px 0px 120px;
    /* Center the header */
    /* padding: 20px; */
    font-weight: 700;
    font-size: 38px;
}

.title-card {
    width: max-content;
}

.title-card-header {
    font-size: 45px;
    font-weight: 800;
}

.ai-blogs-border {
    border-radius: 5px;
    border: 1px solid #16141f;
    width: 150%;
    padding: 15px;
    background-color: #16141f;
}

.card .GatsbyImage {
    display: block;
    width: 100%;
    border-radius: 12px;
    height: auto;
}

.GatsbyImage::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    /* Adjust shadow color and size as needed */
    z-index: 1;
    /* Ensure the shadow is behind the image */
}

.card-img {
    position: relative;
    overflow: hidden;
    display: inline-block;
    height: fit-content;
}

.card-img::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    /* Adjust shadow height as needed */
    background: linear-gradient(0deg,
            #000,
            transparent 85.52%);
    /* Shadow color */
    z-index: 1;
    /* Ensure the shadow is behind the image */
}

.card-title-imgae {
    position: absolute;
    top: 86%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    width: 300px;
}

.card-title-imgaes {
    /* line-height: 10px; */
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    padding: 10px 20px;
    font-size: 12px;
    text-align: center;
    /* box-shadow: inset 0 0 8px #090808; */
    width: 300px;
}

.card-title-imgaess {
    /* line-height: 10px; */
    position: absolute;
    top: 88%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    padding: 10px 20px;
    font-size: 28px;
    text-align: center;
    /* box-shadow: inset 0 0 8px #090808; */
    width: 300px;
}

.imageheight {
    height: 300px;
}

.popup-header {
    font-size: 30px;
}

#signup_form input:not([type='checkbox']) {
    /* background: #000000; */
    border: #ccc solid 1px;
    /* color: white; */
    /* text-align: center; */
    border-radius: 0.3rem;
}